<template>
  <div class="custom-editable-input-th">
    <div
      @dblclick="editProduct"
      class="show-value"
      :class="{ 'show-value-l': showValueL }"
    >
      {{ item[valueToShow] }}{{ uniteMontant
      }}<font-awesome-icon
        class="show-view-pencil ml-2"
        icon="pencil-alt"
        :class="{
          'show-editable-icon': showIconEdit == true && item.lock == false
        }"
        v-if="editable == true && item.lock == false && item.master == false"
      />
    </div>

    <v-dialog
      v-model="dialog"
      persistent
      max-width="1300px"
      :scrollable="true"
      :hide-overlay="true"
      no-click-animation
      content-class="custom-vuetify-dialog-add"
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            {{ item.project_numero }} : {{ item.objet }} ({{ item.vendeur }})
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="modalProductSimulation('hide')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr"></v-divider>
        <v-card-text class="body-card">
          <v-row>
            <v-col cols="11"> </v-col>
            <v-col cols="1">
              <button
                type="button"
                class="btn-product"
                @click="handleAddNewProduct"
              >
                <font-awesome-icon icon="plus" class="icon-plus" />

                Produit
              </button>
            </v-col>
          </v-row>
          <form class="form-add">
            <v-row
              v-for="(product, index) in FactureToUpdate"
              :key="'product' + index"
            >
              <v-col cols="5">
                <v-text-field
                  label="Nom"
                  :persistent-placeholder="true"
                  required
                  v-model="product.nom"
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  label="PU HT"
                  :persistent-placeholder="true"
                  required
                  outlined
                  v-model="product.pu_ht"
                  :disabled="product.unite != '' && product.id != null"
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col cols="2">
                <v-select
                  placeholder="Séléctionnez"
                  :items="listUnite"
                  class="select-menu"
                  label="Unité"
                  :persistent-placeholder="true"
                  v-model="product.unite"
                  :menu-props="{ bottom: true, offsetY: true }"
                  item-value="id"
                  item-text="name"
                  no-data-text="Aucune unité"
                  outlined
                  dense
                  :auto-focus="false"
                  @change="
                    product.unite == ''
                      ? (product.qte = 1)
                      : (product.qt = product.qt)
                  "
                  color="#704ad1"
                  item-color="#704ad1"
                >
                </v-select>
              </v-col>

              <v-col cols="2">
                <v-text-field
                  label="Qté"
                  :persistent-placeholder="true"
                  required
                  v-model="product.qte"
                  :disabled="product.unite == '' && product.id != null"
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col cols="1">
                <font-awesome-icon
                  @click="handleDeleteNewProduct(index)"
                  color="red"
                  icon="trash"
                  class="icon-trash-product"
                />
              </v-col>
            </v-row>
          </form>
        </v-card-text>
        <div v-if="errorUpdate" class="message-error-modal">
          <ul v-if="Array.isArray(errorUpdate)" class="mb-0">
            <li v-for="(e, index) in errorUpdate" :key="index">
              {{ e }}
            </li>
          </ul>
          <div class="message-error-modal" v-else>{{ errorUpdate }}</div>
        </div>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            text
            @click="handleUpdate"
            :loading="loadingUpdate"
            :disabled="loadingUpdate"
            :class="{ loader: loadingUpdate }"
          >
            Ajouter
          </v-btn>
          <v-btn text @click="modalProductSimulation('hide')">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name : 'EditableInputSimulationTh',
  props: {
    value: {
      required: true
    },
    item: {
      required: false
    },
    widthFix: { default: false },
    champName: { required: true },
    updateFunction: { required: true },
    valueToShow: { required: true },
    editable: { default: true },
    showIconEdit: { default: false },
    uniteMontant: { default: '' },
    showValueL: {
      default: false
    },
    showBlock: { default: false }
  },
  data() {
    return {
      errorUpdate: null,
      loadingUpdate: false,
      dialog: false,
      FactureToUpdate: null,
      listUnite: [
        { id: '', name: '' },
        { id: '%', name: '%' }
      ],
      loaderUpdateLoch: false
    };
  },
  methods: {
    ...mapActions([
      'lockFactureSimulation',
      'generateFactureProformaSimulation'
    ]),
    cancel() {
      this.modalProductSimulation('hide');
    },
    modalProductSimulation(action) {
      if (action == 'hide') {
        this.dialog = false;
        this.resetModal();
      } else {
        this.dialog = true;
      }
    },
    resetModal() {
      this.FactureToUpdate = null;
      this.loadingUpdate = false;
      this.errorUpdate = null;
    },
    handleAddNewProduct() {
      this.FactureToUpdate.push({
        nom: null,
        pu_ht: 0,
        unite: '%',
        qte: 0
      });
    },
    handleDeleteNewProduct(index) {
      this.FactureToUpdate.splice(index, 1);
    },
    async handleUpdate() {
      this.errorUpdate = null;
      let params = {
        data: this.item,
        project_id: this.item.project_id,
        vendeur_id: this.item.vendeur_id,
        objet: this.item.objet,
        tva: this.item.tva,
        products: this.FactureToUpdate,
        client_id: this.item.client_id
      };
      if (this.item.facture_id) {
        params.id_facture = this.item.facture_id;
      }
      this.loadingUpdate = true;
      const response = await this.generateFactureProformaSimulation(params);
      if (response?.succes) {
        this.loadingUpdate = false;
        this.cancel();
      } else {
        this.errorUpdate = response.error;
        this.loadingUpdate = false;
      }
    },
    editProduct() {
      if (this.item.lock == false && this.item.master == false) {
        this.FactureToUpdate = JSON.parse(JSON.stringify(this.item.products));
        this.modalProductSimulation('show');
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.btn-product {
  border: none !important;
  background: none !important;
  box-shadow: none !important;
  font-weight: 600 !important;
  color: #050505;
  &:hover {
    background: none !important;
  }
}
.icon-trash-product {
  cursor: pointer;
  margin-top: 8px;
  float: right;
  margin-right: 26px;
}
.form-add {
  margin-top: 10px;
}
.custom-editable-input-th {
  justify-content: center;
  display: flex;
  .show-value {
    min-width: 10px;
    min-height: 10px;
    display: flex;
    justify-content: space-between;
    align-items: start;
    justify-content: start;
    cursor: pointer;
    .show-view-pencil {
      color: #050505;
      opacity: 0;
      display: none;
      transition: opacity 0.2s ease;
    }
    .show-editable-icon {
      display: inline-block;
      opacity: 1;
    }
    &:hover {
      .show-view-pencil {
        display: inline-block;
        opacity: 1;
      }
    }
  }
  .show-value-l {
    min-width: 82px;
  }
}
</style>
